import "./affliate.scss";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Envirnoment from "../../utils/environment";
import { useWeb3React } from "@web3-react/core";
import moment from "moment";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";

const History = () => {
  const [filByType, setfilByType] = useState("Filter By Type");
  let filByTypeArray = [
    "HYDT withdrawal",
    "HYGT withdrawal",
    "Referral Registration",
    "HYDT commission",
    "HYGT commission",
    "HYDT withdrawal approval",
    "HYGT withdrawal approval",
    "HYDT withdrawal request",
    "HYGT withdrawal request",
  ];
  const { account } = useWeb3React();
  const accessToken = localStorage.getItem("accessToken");
  const [history, setHistory] = useState();
  const [offset, setOffset] = useState(1);

  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  function getHistoryFunc() {
    const params = {
      offset: offset,
      limit: 10,
    };

    if (filByType !== null && filByType !== "Filter By Type") {
      params.type = filByType;
    }

    axios
      .get(Envirnoment.apiUrl + "history/get-my-history", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: params,
      })
      .then((response) => {
        // You can perform additional actions based on the API response
        const resData = response?.data?.data;
        setPages(resData?.pages);
        setHistory(resData);
      })
      .catch((error) => {
        // Handle API errors here
        // toast.error(error.request?.statusText)
      })
      .finally(() => {
        // setIsConfirmLoading(false);
      });
  }

  const handleChangePage = (event, newPage) => {
    // if (newPage == 1) {
    //   setPaginate(1);
    // }
    setOffset(newPage);
  };

  useEffect(() => {
    if (account) {
      getHistoryFunc();
    }
  }, [account, filByType, offset, currentPage]);

  return (
    <>
      <section className="affliate-history">
        <div className="upper-filters">
          <div class="dropdown">
            <button
              class={
                filByType === "Filter By Type"
                  ? "dropdown-toggle"
                  : "dropdown-toggle text-light"
              }
              type="button"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              {filByType}{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M18.4375 6.5625H1.5625C1.31386 6.5625 1.0754 6.46373 0.899587 6.28791C0.723772 6.1121 0.625 5.87364 0.625 5.625C0.625 5.37636 0.723772 5.1379 0.899587 4.96209C1.0754 4.78627 1.31386 4.6875 1.5625 4.6875H18.4375C18.6861 4.6875 18.9246 4.78627 19.1004 4.96209C19.2762 5.1379 19.375 5.37636 19.375 5.625C19.375 5.87364 19.2762 6.1121 19.1004 6.28791C18.9246 6.46373 18.6861 6.5625 18.4375 6.5625ZM15.3125 10.9375H4.6875C4.43886 10.9375 4.2004 10.8387 4.02459 10.6629C3.84877 10.4871 3.75 10.2486 3.75 10C3.75 9.75136 3.84877 9.5129 4.02459 9.33709C4.2004 9.16127 4.43886 9.0625 4.6875 9.0625H15.3125C15.5611 9.0625 15.7996 9.16127 15.9754 9.33709C16.1512 9.5129 16.25 9.75136 16.25 10C16.25 10.2486 16.1512 10.4871 15.9754 10.6629C15.7996 10.8387 15.5611 10.9375 15.3125 10.9375ZM11.5625 15.3125H8.4375C8.18886 15.3125 7.9504 15.2137 7.77459 15.0379C7.59877 14.8621 7.5 14.6236 7.5 14.375C7.5 14.1264 7.59877 13.8879 7.77459 13.7121C7.9504 13.5363 8.18886 13.4375 8.4375 13.4375H11.5625C11.8111 13.4375 12.0496 13.5363 12.2254 13.7121C12.4012 13.8879 12.5 14.1264 12.5 14.375C12.5 14.6236 12.4012 14.8621 12.2254 15.0379C12.0496 15.2137 11.8111 15.3125 11.5625 15.3125Z"
                  fill="white"
                />
              </svg>
            </button>
            <ul class="dropdown-menu">
              {filByTypeArray?.map((item, id) => {
                return (
                  <li id={id}>
                    <a
                      onClick={() => setfilByType(item)}
                      class={"dropdown-item"}
                      href="#"
                    >
                      {item}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* <div class="dropdown">
                        <button class="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                            Filter By Level <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M18.4375 6.5625H1.5625C1.31386 6.5625 1.0754 6.46373 0.899587 6.28791C0.723772 6.1121 0.625 5.87364 0.625 5.625C0.625 5.37636 0.723772 5.1379 0.899587 4.96209C1.0754 4.78627 1.31386 4.6875 1.5625 4.6875H18.4375C18.6861 4.6875 18.9246 4.78627 19.1004 4.96209C19.2762 5.1379 19.375 5.37636 19.375 5.625C19.375 5.87364 19.2762 6.1121 19.1004 6.28791C18.9246 6.46373 18.6861 6.5625 18.4375 6.5625ZM15.3125 10.9375H4.6875C4.43886 10.9375 4.2004 10.8387 4.02459 10.6629C3.84877 10.4871 3.75 10.2486 3.75 10C3.75 9.75136 3.84877 9.5129 4.02459 9.33709C4.2004 9.16127 4.43886 9.0625 4.6875 9.0625H15.3125C15.5611 9.0625 15.7996 9.16127 15.9754 9.33709C16.1512 9.5129 16.25 9.75136 16.25 10C16.25 10.2486 16.1512 10.4871 15.9754 10.6629C15.7996 10.8387 15.5611 10.9375 15.3125 10.9375ZM11.5625 15.3125H8.4375C8.18886 15.3125 7.9504 15.2137 7.77459 15.0379C7.59877 14.8621 7.5 14.6236 7.5 14.375C7.5 14.1264 7.59877 13.8879 7.77459 13.7121C7.9504 13.5363 8.18886 13.4375 8.4375 13.4375H11.5625C11.8111 13.4375 12.0496 13.5363 12.2254 13.7121C12.4012 13.8879 12.5 14.1264 12.5 14.375C12.5 14.6236 12.4012 14.8621 12.2254 15.0379C12.0496 15.2137 11.8111 15.3125 11.5625 15.3125Z" fill="white" />
                            </svg>
                        </button>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#">Commision</a></li>
                            <li><a class="dropdown-item" href="#">Referral Registration</a></li>
                            <li><a class="dropdown-item" href="#">Withdrawal Approval</a></li>
                            <li><a class="dropdown-item" href="#">Withdrawal Request</a></li>

                        </ul>
                    </div> */}
        </div>
        <div className="custom-table">
          <div className="upper-head">
            <div className="set-specific-width">
              <h6>Time</h6>
            </div>
            <div className="set-specific-width">
              <h6>Type</h6>
            </div>
            <div className="set-specific-width">
              <h6>Level</h6>
            </div>
            <div className="set-specific-width">
              <h6>Amount</h6>
            </div>
            <div className="set-specific-width">
              <h6>Username</h6>
            </div>
            <div className="set-specific-width">
              <h6>Address</h6>
            </div>
          </div>
          <div className="bottom-body">
            {history?.histories.map((item, id) => {
              return (
                <div className="trow">
                  <div className="set-specific-width">
                    <div className="twice-text">
                      <p>{moment(item?.createdAt).format("DD/MM/YYYY")}</p>
                      <p className="date-text">
                        {moment(item?.createdAt).format("hh:mm A")}
                      </p>
                    </div>
                  </div>
                  <div className="set-specific-width">
                    {" "}
                    <p>{item?.type}</p>
                  </div>
                  <div className="set-specific-width">
                    <p>{item?.level}</p>
                  </div>
                  <div className="set-specific-width">
                    <p>{item?.amount}</p>
                  </div>
                  <div className="set-specific-width">
                    {item?.walletAddress &&
                      item?.walletAddress?.toLowerCase() !=
                      account?.toLowerCase() ? (
                      <p>{item?.userName || "N/A"}</p>
                    ) : (
                      <p>You</p>
                    )}
                  </div>
                  <div className="set-specific-width">
                    <p>
                      {item?.walletAddress?.slice(0, 11)}...
                      {item?.walletAddress?.slice(
                        item?.walletAddress?.length - 3,
                        item?.walletAddress?.length
                      )}{" "}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
         
        </div>
        {offset > 0 && (
            <div className="pagination-custom">
              <nav aria-label="Page navigation example">
                <Stack spacing={2}>
                  <Pagination
                    size={"small"}
                    count={pages}
                    color="primary"
                    className="materal__pages"
                    showFirstButton
                    showLastButton
                    page={offset}
                    onChange={handleChangePage}
                  />
                </Stack>
              </nav>
            </div>
          )}
      </section>
    </>
  );
};

export default History;
