import { useCallback, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3";
import Environment from "../../utils/environment";
import { farmAbi } from "../../utils/contractHelpers";
import { lpTokenAbi } from "../../utils/contractHelpers";
import { uniSwapRouter } from "../../utils/contractHelpers";
import BigNumber from "bignumber.js";

export const FarmsApy = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contractAddress = Environment.FarmAbi;
  const contract = farmAbi(contractAddress, web3);
  const conAddressRouter = Environment.routerContract;
  // const [token0Price,setToken0Price] =useState(0);
  // const [token1Price,setToken1Price] =useState(0);

  const farmsPercent = useCallback(
    async (pid, token, totalAll) => {
      try {
        let token0Price = 0;
        let token1Price = 0;

        const contractAddress1 = token;
        const contract1 = lpTokenAbi(contractAddress1, web3);

        const getToken0 = await contract1.methods.token0().call();

        const getToken1 = await contract1.methods.token1().call();

        const contract2 = lpTokenAbi(getToken0, web3);
        const contract3 = lpTokenAbi(getToken1, web3);

        //balanceOf  token0
        const getBalOfToken0 = await contract2.methods
          .balanceOf(contractAddress1)
          .call();
        const getBalOfToken00 = web3.utils.fromWei(getBalOfToken0, "ether");

        //decimals token0
        const getDeciToken0 = await contract2.methods.decimals().call();
        //Router input-0
        const routInput0 = 1 * Math.pow(10, getDeciToken0);
        //         let balance=1;
        //         const routInput0 = (balance).multipliedBy(new BigNumber(10).pow(getDeciToken0)).toFixed()
        // console.log("hererererer",routInput0);

        //balanceOf  token1
        const getBalOfToken1 = await contract3.methods
          .balanceOf(contractAddress1)
          .call();
        const getBalOfToken11 = web3.utils.fromWei(getBalOfToken1, "ether");

        //decimals token1
        const getDeciToken1 = await contract3.methods.decimals().call();
        //Router input-1
        const routInput1 = 1 * Math.pow(10, getDeciToken1);

        const wbnbContract = "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6";
        if (wbnbContract == getToken0) {
          token0Price = 1;
        } else {
          try {
            const path = [getToken0, wbnbContract];
            let amount = routInput0.toString();
            const routerContract = uniSwapRouter(conAddressRouter, web3);
            let getAmountsOut = await routerContract.methods
              .getAmountsOut(amount, path)
              .call();
            token0Price = web3.utils.fromWei(getAmountsOut[1], "ether");
          } catch (error) {
            const path = [getToken0, getToken1, wbnbContract];

            let amount = routInput0.toString();
            const routerContract = uniSwapRouter(conAddressRouter, web3);
            let getAmountsOut = await routerContract.methods
              .getAmountsOut(amount, path)
              .call();
            token0Price = web3.utils.fromWei(getAmountsOut[2], "ether");
          }
        }

        if (wbnbContract == getToken1) {
          token1Price = 1;
        } else {
          try {
            const path = [getToken1, wbnbContract];
            const routerContract = uniSwapRouter(conAddressRouter, web3);
            let amount = routInput1.toString();
            let getAmountsOut = await routerContract.methods
              .getAmountsOut(amount, path)
              .call();
            token1Price = web3.utils.fromWei(getAmountsOut[1], "ether");
          } catch {
            console.log("2222222555555666666");
            token0Price = 0;
          }
        }

        const REWARD_PER_BLOCK = 2;
        const BLOCKS_PER_DAY = 7200;
        const BLOCKS_PER_YEAR = 2628000;

        //totalAllocPoint
        const totalAllocPoints = await contract.methods
          .totalAllocPoint()
          .call();

        //allocWeightage
        const allocWeightage = totalAll / totalAllocPoints;
        //
        const rewardTokenQuote = 0.00331473546012118975977681155454;
        const totalRewardPricePerYear =
          rewardTokenQuote *
          REWARD_PER_BLOCK *
          BLOCKS_PER_YEAR *
          allocWeightage;

        const totalLpDepFarmContract = await contract1.methods
          .balanceOf(contractAddress)
          .call();
        const totalLpDepFarmCon = web3.utils.fromWei(
          totalLpDepFarmContract,
          "ether"
        );

        //Reserves
        const Reserves = await contract1.methods.getReserves().call();
        const reserve0 = web3.utils.fromWei(Reserves?._reserve0, "ether");
        const reserve1 = web3.utils.fromWei(Reserves?._reserve1, "ether");

        //TotalSupply
        const TotalSupply = await contract1.methods.totalSupply().call();
        const TotalSupply1 = web3.utils.fromWei(TotalSupply, "ether");

        const lpTokenPrice =
          (token0Price * reserve0 + token1Price * reserve1) / TotalSupply1;

        const secLast = totalLpDepFarmCon * lpTokenPrice;

        const apy = (totalRewardPricePerYear / secLast) * 100;
        return apy;
      } catch (error) {
        console.log("uuuuuuuuuuuuuuuuu", error);
        console.log("2222222555555666666", pid);
        throw error;
      }
    },
    [contract]
  );
  return { farmsPercent: farmsPercent };
};
export default FarmsApy;
