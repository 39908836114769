import React, { useEffect, useState } from "react";
import axios from "axios";
import Envirnoment from "../../../utils/environment";
import { useWeb3React } from "@web3-react/core";
import Signature from "../../../hooks/userSign";
import { Modal } from "react-bootstrap";
import ClaimRefRewards from "../../../hooks/dataSenders/claimRefReward";
import { toast } from "react-toastify";
import UserTotalWithdrawals from "hooks/dataFetchers/userTotalWithdrawals";
import ClaimWithdrawalRow from "./ClaimWithdrawalRow";
export default function ClaimWithdrawal({
  affiliateWithdrawBalance,
  hygtCommission,
}) {
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => {
    setShow2(true);
  };
  const { account } = useWeb3React();
  const [awaitApprovalList, setAwaitApprovalList] = useState();
  const accessToken = localStorage.getItem("accessToken");
  const [offset, setOffset] = useState(1);
  let { refRewards } = ClaimRefRewards();
  const { userSign } = Signature();
  const { totalWithdrawals } = UserTotalWithdrawals();
  const [loading, setIsLoading] = useState(false);
  function getHistoryFunc() {
    const params = {
      offset,
      limit: 10,
      status: ["approved", "claimed"],
      hygtVestingClaimed: "false",
    };

    axios
      .get(Envirnoment.apiUrl + "withdraw/get-user-withdraw-list", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: params,
      })
      .then((response) => {
        // You can perform additional actions based on the API response
        setAwaitApprovalList(response?.data?.data?.withdraws);
      })
      .catch((error) => {
        // Handle API errors here
        // toast.error(error.request?.statusText)
      })
      .finally(() => {
        // setIsConfirmLoading(false);
      });
  }

  const parseContractError = (error) => {
    try {
      // Check if the error contains a JSON message (often inside "message" property)
      const errorMessageMatch = error.message.match(/{[^}]+}/);
      if (errorMessageMatch) {
        const parsedError = JSON.parse(errorMessageMatch[0]);

        // Extract the main parts of the error
        const essentialMessage = parsedError.message || "An error occurred";
        return essentialMessage;
      } else {
        // If no structured error message, fallback to the main message
        return error.message || "An error occurred";
      }
    } catch (parseError) {
      // If error parsing fails, just return the original error message
      return error.message || "An unknown error occurred";
    }
  };

  const showErrorToast = (error) => {
    const essentialErrorMessage = parseContractError(error);

    toast.error(essentialErrorMessage, {
      // position: "top-right",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
        maxWidth: "600px",
      },
    });
  };
  const claimContract = async (signData, data, bool) => {
    let signature = signData?.adminSign;
    // signature = signature.substring(2)
    // let r = '0x' + signature.slice(0, 64);
    // let s = '0x' + signature.slice(64, 128);
    // let v = '0x' + signature.slice(128, 130);

    var r = "0x" + signature.slice(2, 66);
    var s = "0x" + signature.slice(66, 130);
    var v = parseInt(signature.slice(130, 132), 16);
    const currentTimeEpoch = signData?.signTime;
    console.log(
      signData?.amount,
      signData?.hygtAmount,
      signData?.hygtVestingType,
      currentTimeEpoch,
      v,
      r,
      s
    );
    try {
      let res = await refRewards(
        signData?.amount,
        signData?.hygtAmount,
        signData?.hygtVestingType,
        currentTimeEpoch,
        v,
        r,
        s,
        bool,
        data?.hygtVestingNumber
      );
      return res;
    } catch (error) {
      console.log(error);
      showErrorToast(error);
    }
  };
  async function claimGetData(id) {
    try {
      setIsLoading(true);
      const sign = await userSign(account?.toString());

      const response = await axios.get(Envirnoment.apiUrl + `withdraw/${id}`, {
        params: {
          sign,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Handle the API response here
      // You can perform additional actions based on the API response
      return response.data?.data;
    } catch (error) {
      // Handle API errors here
      console.error("Error claiming withdraw:", error);
    } finally {
      // setIsLoading(false);
    }
  }

  async function claim(item) {
    try {
      setIsLoading(true);
      let res = await claimGetData(item?.id);
      let contractRes = await claimContract(res, item, false);
      let withdrawlNo = await totalWithdrawals();
      console.log(withdrawlNo);
      if (contractRes) {
        let withdrawNum;
        if (parseInt(withdrawlNo) === 0) {
          withdrawNum = 1;
        } else {
          withdrawNum = withdrawlNo;
        }
        const response = await axios.post(
          Envirnoment.apiUrl + "withdraw/claim-withdraw",
          {
            withdrawId: item?.id,
            hygtVestingNumber: withdrawlNo - 1,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (item?.hygtVestingType === "no vesting") {
          toast.success("HYDT & HYGT Claimed Successfully");
        } else if (item?.hygtVestingType === "three months") {
          toast.info("You can claim HYGT after Three Months");
        } else if (item?.hygtVestingType === "twelve months") {
          toast.info("You can claim HYGT after Twelve Months");
        }
        // item?.hygtVestingType === '' &&
        getHistoryFunc();
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error?.message);
      setIsLoading(false);
      // Handle API errors here
    } finally {
      setIsLoading(false);
    }
  }
  async function claimTimer(item) {
    try {
      setIsLoading(true);
      let res = await claimGetData(item?.id);
      let contractRes = await claimContract(res, item, true);
      let withdrawlNo = await totalWithdrawals();
      console.log(withdrawlNo);
      if (contractRes) {
        const response = await axios.post(
          Envirnoment.apiUrl + "withdraw/claim-withdraw",
          {
            withdrawId: item?.id,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        toast.success("Claimed Successfully");
        getHistoryFunc();
      }
    } catch (error) {
      toast.error(error?.message);
      // Handle API errors here
      console.error("Error claiming withdraw:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (account && show2) {
      getHistoryFunc();
    }
  }, [account, offset, show2]);
  return (
    <>
      <div className="single-card">
        <div className="top-heading">
          <h6>Claim Withdrawal</h6>
        </div>
        <div className="fourth-set-align">
          <div className="bottom-text">
            <div className="third-text">
              <div className="upper-t">
                <h6>
                  {affiliateWithdrawBalance?.approvedReward < 0.00001 ||
                  affiliateWithdrawBalance?.approvedReward == null ||
                  affiliateWithdrawBalance?.approvedReward === undefined
                    ? 0
                    : parseFloat(
                        affiliateWithdrawBalance?.approvedReward
                      )?.toFixed(4)}
                </h6>
                <p>HYDT</p>
                <img
                  src="\assests\hydt-new-icon.svg"
                  alt="img"
                  className="img-fluid"
                />
              </div>
              <div className="upper-t">
                <h6>
                  {affiliateWithdrawBalance?.approvedHygtReward < 0.00001 ||
                  affiliateWithdrawBalance?.approvedHygtReward == null ||
                  affiliateWithdrawBalance?.approvedHygtReward === undefined
                    ? 0
                    : parseFloat(
                        affiliateWithdrawBalance?.approvedHygtReward
                      )?.toFixed(4)}

                  {/* {(affiliateWithdrawBalance?.approvedReward || 0) *
                    hygtCommission} */}
                </h6>
                <p>HYGT</p>
                <img
                  src="\assests\hygt-new-icon.svg"
                  alt="img"
                  className="img-fluid"
                />
              </div>
              <div className="upper-t">
                <h6>
                  {affiliateWithdrawBalance?.approvedHygtVestingReward <
                    0.00001 ||
                  affiliateWithdrawBalance?.approvedHygtVestingReward == null ||
                  affiliateWithdrawBalance?.approvedHygtVestingReward ===
                    undefined
                    ? 0
                    : parseFloat(
                        affiliateWithdrawBalance?.approvedHygtVestingReward
                      )?.toFixed(4)}

                  {/* {affiliateWithdrawBalance?.vestingReward || 0} */}
                </h6>
                <p>HYGT Vesting</p>
              </div>
            </div>
          </div>
          <div className="bottom-btn">
            <a className={awaitApprovalList?.length === 0 ? "request-btn disable" : "request-btn"} onClick={awaitApprovalList?.length === 0 ? null : handleShow2}>
              Claim Withdraw
            </a>
          </div>
        </div>
      </div>
      <Modal
        className="request-withdraw-modal awaiting-approval-modal"
        show={show2}
        onHide={handleClose2}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Claim Withdraw</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="hcvttvdvctvcv">
            <div className="custom-table ">
              <div className="upper-head">
                <h6 className="widthsmall">Date/Time</h6>
                <h6 className="widthsmall">HYDT Amount</h6>
                <h6 className="widthsmall">HYGT Amount</h6>
                <h6 className="widthsmall">HYGT Vesting Periods</h6>
                <h6 className="widthlarge">Action</h6>
              </div>
              <div className="bottom-body">
                {awaitApprovalList?.map((item, indes) => {
                  const dateString = item?.hygtVestingEndTime;
                  const date = new Date(dateString);

                  // Format the date in the desired format "09/02/2024 3:00 PM"
                  const formattedDate = `${(date.getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}/${date
                    .getDate()
                    .toString()
                    .padStart(
                      2,
                      "0"
                    )}/${date.getFullYear()} ${date.toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}`;

                  let status = item?.status;
                  return (
                    <ClaimWithdrawalRow
                      formattedDate={formattedDate}
                      item={item}
                      claim={claim}
                      claimTimer={claimTimer}
                      loading={loading}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
