import React, { useState, useEffect } from "react";

const Timer = ({ targetEpochTime, claimTimer, item }) => {
  // Calculate the initial time difference in seconds
  const calculateTimeLeft = () => {
    const currentTime = Math.floor(Date.now() / 1000);
    const timeLeft = targetEpochTime - currentTime;
    return Math.max(timeLeft, 0);
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [timeIsUp, setTimeIsUp] = useState(false);

  useEffect(() => {
    // Update the timer every second
    const interval = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();

      if (newTimeLeft <= 0) {
        clearInterval(interval);
        setTimeIsUp(true);
      }

      setTimeLeft(newTimeLeft);
    }, 1000);

    return () => clearInterval(interval);
  }, [targetEpochTime]);

  // Function to format time
  const formatTime = (seconds) => {
    const days = Math.floor(seconds / (24 * 3600));
    seconds %= 24 * 3600;
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;

    return (
      <div>
        <span style={{ whiteSpace: "nowrap" }}>
          HYDT Claimed - HYGT Available in{" "}
        </span>{" "}
        <br /> {days || 0} D : {hours || 0} H : {minutes || 0} M :{" "}
        {seconds || 0} S
      </div>
    );
  };

  return (
    <div>
      {/* <h2>Countdown Timer</h2> */}
      {/* <p>{timeIsUp ? 'Time is up!' : formatTime(timeLeft)}</p> */}
      {timeIsUp ? (
        <button onClick={() => claimTimer(item)} className="btn-claim">
          Claim HYGT
        </button>
      ) : (
        <button className="btn-claim bg-transparent flex-col">
          <span>{formatTime(timeLeft)}</span>
        </button>
      )}
    </div>
  );
};

export default Timer;
