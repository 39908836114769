import "./dashboard.scss";
import { useHistory } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import ClaimRewardsD from "../../hooks/dataFetchers/claimRewardsD";
import ClaimRewardsD1 from "../../hooks/dataFetchers/claimRewardsD1";
import ClaimRewardsD2 from "../../hooks/dataFetchers/claimRewardsD2";
import DepositRewardsD2 from "../../hooks/dataFetchers/depositRewardsD2";
import Loader from "../../hooks/loader";
import useWeb3 from "../../hooks/useWeb3";
import { useWeb3React } from "@web3-react/core";
import { useState, useEffect } from "react";
import Environment from "../../utils/environment";
import axios from "axios";
import DepositRewardsD1 from "../../hooks/dataFetchers/depositRewardsD1";
import { Modal } from "react-bootstrap";

function Dashboard() {
  const history = useHistory();
  const { dashRewards } = ClaimRewardsD();
  const { dashRewardsOne } = ClaimRewardsD1();
  const { dashRewardsTwo } = ClaimRewardsD2();
  const [pendingRew, setPendingRew] = useState("");
  const [pendingRew1, setPendingRew1] = useState("");
  const [pendingRew2, setPendingRew2] = useState("");
  const [amountActive, setAmount] = useState("");
  const [dollarVal, setDollVal] = useState();
  const { dashdepositd1 } = DepositRewardsD1();
  const { dashdepositd2 } = DepositRewardsD2();
  const [depositRew, DepositRewardsD] = useState("");
  const [depositRew1, setDepositRewardsD1] = useState("");
  const [depositRew2, setDepositRewardsD2] = useState("");
  const [mainLoader, setMainLoader] = useState(false);
  const [totHygtReward, setTotHygtReward] = useState(0.0000000000)
  const web3 = useWeb3();
  const { account } = useWeb3React();
  const handleRew = async () => {
    try {
      setMainLoader(true);
      let result = await dashRewards();
      setPendingRew(result);
      setMainLoader(false);
    } catch {
      setMainLoader(false);
    }
  };
  const handleRewone = async () => {
    try {
      setMainLoader(true);
      let result = await dashRewardsOne();
      setPendingRew1(result);
      setMainLoader(false);
    } catch {
      setMainLoader(false);
    }
  };
  const handleRewtwo = async () => {
    try {
      setMainLoader(true);
      let result = await dashRewardsTwo();
      setPendingRew2(result);
      setMainLoader(false);
    } catch {
      setMainLoader(false);
    }
  };

  const handleDepRewtwo = async () => {
    try {
      setMainLoader(true);
      let result = await dashdepositd2();
      setDepositRewardsD2(result);
      setMainLoader(false);
    } catch {
      setMainLoader(false);
    }
  };
  const handleDepRewOne = async () => {
    try {
      setMainLoader(true);
      let result = await dashdepositd1();
      setDepositRewardsD1(result);
      setMainLoader(false);
    } catch {
      setMainLoader(false);
    }
  };

  useEffect(() => {
    handleRew();
    handleRewone();
    handleRewtwo();
    handleDepRewtwo();
    handleDepRewOne();
  }, [account]);
  //Graph Apis

  const getActiveDeposit = () => {
    setMainLoader(true);
    let account1 = account.toLowerCase();
    let acc = JSON.stringify(account1 + "-Earn");
    // let accounttt = acc.split("-")[1];
    var data = JSON.stringify({
      query: `query 
      MyQuery {userVolumes(where: {id: ${acc}}) {
        amount
    }
      }`,
    });
    var config = {
      method: "post",
      url: Environment.hydtGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setAmount(
          web3.utils.fromWei(
            response?.data?.data?.userVolumes[0].amount,
            "ether"
          )
        );
        setMainLoader(false);
      })
      .catch(function (error) {
        setMainLoader(false);
      });
  };
  const userActiveStake = async (type) => {
    setMainLoader(true);
    let account1 = account.toLowerCase();;
    let acc = JSON.stringify(account1);
    // Get current time in milliseconds since Unix epoch
    const currentTimeInMillis = Date.now();

    // Convert milliseconds to seconds
    const currentTimeInSeconds = Math.floor(currentTimeInMillis / 1000);
    var data = JSON.stringify({
      query: `query MyQuery {
  stakes(
    where: {stakeType: ${type}, user: ${acc}, status: true, endTime_gt: ${currentTimeInSeconds}}
    orderBy: blockTimestamp
    orderDirection: asc
    skip: 0
  ) {
    amount
  }
}`,
    });

    var config = {
      method: 'post',
      url: Environment.hydtGraph, // Replace with Environment.hydtGraph
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    try {
      const response = await axios(config);
      let res = response.data.data.stakes;

      // Convert wei to ether and sum
      const totalInWei = res.reduce((total, item) => {
        return web3.utils.toBN(total).add(web3.utils.toBN(item.amount));
      }, web3.utils.toBN('0'));

      // Convert the total amount from wei to ether
      const totalInEther = web3.utils.fromWei(totalInWei, 'ether');
      console.log(`ttttttttttt user type ${type}`, totalInEther);

      setMainLoader(false);
      return totalInEther;
    } catch (error) {
      console.error("Error fetching stakes:", error);
      setMainLoader(false);
    }
  };
  const totalActiveStake = async (type) => {
    setMainLoader(true);

    const currentTimeInMillis = Date.now();
    const currentTimeInSeconds = Math.floor(currentTimeInMillis / 1000);

    const PAGE_SIZE = 20; // Define the page size (number of items per page)
    let hasMoreData = true;
    let skip = 0;
    let totalInWei = web3.utils.toBN('0');

    while (hasMoreData) {
      const data = JSON.stringify({
        query: `query MyQuery {
        stakes(
          where: {stakeType: ${type}, status: true, endTime_gt: ${currentTimeInSeconds}}
          orderBy: blockTimestamp
          orderDirection: asc
          first: ${PAGE_SIZE}
          skip: ${skip}
        ) {
          amount
        }
      }`,
      });

      const config = {
        method: 'post',
        url: Environment.hydtGraph,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      try {
        const response = await axios(config);
        const res = response.data.data.stakes;

        if (res.length < PAGE_SIZE) {
          hasMoreData = false;
        }

        totalInWei = totalInWei.add(
          res.reduce((total, item) => total.add(web3.utils.toBN(item.amount)), web3.utils.toBN('0'))
        );

        skip += PAGE_SIZE;
      } catch (error) {
        console.error("Error fetching stakes:", error);
        setMainLoader(false);
        return;
      }
    }

    const totalInEther = web3.utils.fromWei(totalInWei, 'ether');
    console.log(`Total type ${type}`, totalInEther);

    // setMainLoader(false);
    return totalInEther;
  };

//   const totalActiveStake = async (type) => {
//     setMainLoader(true);
//     // Get current time in milliseconds since Unix epoch
//     const currentTimeInMillis = Date.now();

//     // Convert milliseconds to seconds
//     const currentTimeInSeconds = Math.floor(currentTimeInMillis / 1000);
//     var data = JSON.stringify({
//       query: `query MyQuery {
//   stakes(
//     where: {stakeType: ${type}, status: true, endTime_gt: ${currentTimeInSeconds}}
//     orderBy: blockTimestamp
//     orderDirection: asc
//   ) {
//     amount
//   }
// }`,
//     });

//     var config = {
//       method: 'post',
//       url: Environment.hydtGraph, // Replace with Environment.hydtGraph
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       data: data,
//     };

//     try {
//       const response = await axios(config);
//       let res = response.data.data.stakes;

//       // Convert wei to ether and sum
//       const totalInWei = res.reduce((total, item) => {
//         return web3.utils.toBN(total).add(web3.utils.toBN(item.amount));
//       }, web3.utils.toBN('0'));

//       // Convert the total amount from wei to ether
//       const totalInEther = web3.utils.fromWei(totalInWei, 'ether');
//       console.log(`ttttttttttt total type ${type}`, totalInEther);

//       setMainLoader(false);
//       return totalInEther;
//     } catch (error) {
//       console.error("Error fetching stakes:", error);
//       setMainLoader(false);
//     }
//   };
  // let hygtReward =async ()=>{
  //   setMainLoader(true);
  //   let ThreeMonHygtEmission =  5760;
  //   let SixMonthHygtEmission = 17280; 
  //   let TweleveMonthHygtEmission = 34560;
  //  let userActiveStk0 =await  userActiveStake(0)
  //   let userActiveStk1 = await userActiveStake(1)
  //   let userActiveStk2 = await userActiveStake(2)

  //   let totalActiveStk0 = await totalActiveStake(0)
  //   let totalActiveStk1 = await totalActiveStake(1)
  //   let totalActiveStk2 = await totalActiveStake(2)

  //   let threeMonths = parseFloat(userActiveStk0) / parseFloat(totalActiveStk0)
  //   threeMonths = parseFloat(threeMonths) * ThreeMonHygtEmission
     
  //   let sixMonths = parseFloat(userActiveStk1) / parseFloat(totalActiveStk1)
  //   sixMonths = parseFloat(sixMonths) * SixMonthHygtEmission

  //   let twelveMonths = parseFloat(userActiveStk2) / parseFloat(totalActiveStk2)
  //   twelveMonths = parseFloat(twelveMonths) * TweleveMonthHygtEmission

  //   let totalDailyHygtReward = threeMonths + sixMonths + twelveMonths

  //   setMainLoader(false);
  //   console.log("Total in ether:", userActiveStk0, userActiveStk1, userActiveStk2);
  //   console.log("Total in ether: 2", totalActiveStk0, totalActiveStk1, totalActiveStk2);
  //   console.log("Total in ether: 3", threeMonths, sixMonths, twelveMonths);
  //   console.log("Total in ether: 4", totalDailyHygtReward);
  // }
  const hygtReward = async () => {
    setMainLoader(true);

    const THREE_MONTH_HYGT_EMISSION = 5760;
    const SIX_MONTH_HYGT_EMISSION = 17280;
    const TWELVE_MONTH_HYGT_EMISSION = 34560;

    const calculateReward = (userActiveStk, totalActiveStk, emission) => {
      const userStk = parseFloat(userActiveStk);
      const totalStk = parseFloat(totalActiveStk);
      return (userStk / totalStk) * emission;
    };

    try {
      
      const [userActiveStk0, userActiveStk1, userActiveStk2] = await Promise.all([
        userActiveStake(0),
        userActiveStake(1),
        userActiveStake(2),
      ]);

      const [totalActiveStk0, totalActiveStk1, totalActiveStk2] = await Promise.all([
        totalActiveStake(0),
        totalActiveStake(1),
        totalActiveStake(2),
      ]);


      const threeMonths = calculateReward(userActiveStk0, totalActiveStk0, THREE_MONTH_HYGT_EMISSION);
      const sixMonths = calculateReward(userActiveStk1, totalActiveStk1, SIX_MONTH_HYGT_EMISSION);
      const twelveMonths = calculateReward(userActiveStk2, totalActiveStk2, TWELVE_MONTH_HYGT_EMISSION);

      const totalDailyHygtReward = threeMonths + sixMonths + twelveMonths;

      setTotHygtReward(totalDailyHygtReward)
      console.log("User Active Stk:", userActiveStk0, userActiveStk1, userActiveStk2);
      console.log("Total Active Stk:", totalActiveStk0, totalActiveStk1, totalActiveStk2);
      console.log("Rewards:", threeMonths, sixMonths, twelveMonths);
      console.log("Total Daily Hygt Reward:", totalDailyHygtReward);

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setMainLoader(false);
    }
  };

  useEffect(() => {
    if (account) {
      getActiveDeposit();
      hygtReward()
    }
  }, [account]);

  // const [show1, setShow1] = useState(true);
  // const handleClose1 = () => setShow1(false);
  useEffect(() => {
    let dollVal = localStorage.getItem("TokendollorVal");
    dollVal= JSON.parse(dollVal);
    console.log(dollVal?.hydt, 'dollVal');
    
    setDollVal(dollVal);
  }, [account]);
  return (
    <>
      {mainLoader && <Loader />}
      <div className="content">
        <section className="main-dashboard">
          <div className="first">
            <div className="main-heading">
              <h4 className="ml-15">Reward Overview</h4>
            </div>
            <div className="content-main">
              <div className="row">
                <div className="col-xl-4 col-12 padd-sm">
                  <div className="inner-card">
                    <div className="upper-head">
                      <h6>Pending HYDT Rewards</h6>
                    </div>
                    <div className="bottom-content">
                      <div className="left">
                        <p>
                          <img
                            src="\assests\sidebar-icons\earn.svg"
                            alt="img"
                            className="img-fluid mr-2"
                          />
                          HYDT Staking
                        </p>
                        <h6>
                          {pendingRew ? parseFloat(pendingRew).toFixed(6) : 0}{" "}
                          <span>
                            HYDT{" "}
                            <img
                              src="\assests\buttonsvgs\hydt.svg"
                              alt="img"
                              className="img-fluid ml-2x"
                            />
                          </span>
                        </h6>
                      </div>
                      <div className="right">
                        <Link to={"./earn"}>
                          <button className="btn-transparent">Claim</button>
                        </Link>
                      </div>
                    </div>
                    <p className="lowerparadollar">
                      ~${(pendingRew && dollarVal?.hydt) ? parseFloat(parseFloat(pendingRew) * parseFloat(dollarVal?.hydt)).toFixed(6) : 0}{" "}
                    </p>
                  </div>
                </div>
                <div className="col-xl-8 col-12 padd-sm">
                  <div className="inner-card mb-0">
                    <div className="upper-head">
                      <h6>Pending HYGT Rewards</h6>
                    </div>
                    <div className="twice-inline">
                      <div className="cardmain w-100">
                        <div className="bottom-content">
                          <div className="left">
                            <p>
                              <img
                                src="\assests\sidebar-icons\earn.svg"
                                alt="img"
                                className="img-fluid mr-2"
                              />
                              HYDT Staking
                            </p>
                            <h6>
                              {pendingRew1
                                ? parseFloat(pendingRew1).toFixed(6)
                                : 0}
                              <span>
                                HYGT{" "}
                                <img
                                  src="\assests\buttonsvgs\hygt.svg"
                                  alt="img"
                                  className="img-fluid ml-2x" 
                                />
                              </span>
                            </h6>
                          </div>
                          <div className="right">
                            <Link to={"./earn"}>
                              <button className="btn-transparent">Claim</button>
                            </Link>
                          </div>
                        </div>
                        <p className="lowerparadollar">
                          ~$ {(pendingRew1 && dollarVal?.hygt)
                            ? parseFloat(parseFloat(pendingRew1) * parseFloat(dollarVal?.hygt)).toFixed(6)
                            : 0}
                        </p>
                      </div>

                      <span className="line"></span>
                      <div className="cardmain w-100">
                        <div className="bottom-content">
                          <div className="left">
                            <p>
                              <img
                                src="\assests\sidebar-icons\farm.svg"
                                alt="img"
                                className="img-fluid mr-2"
                              />
                              Farm
                            </p>
                            <h6>
                              {pendingRew2
                                ? parseFloat(pendingRew2).toFixed(6)
                                : 0}
                              <span>
                                HYGT{" "}
                                <img
                                  src="\assests\buttonsvgs\hygt.svg"
                                  alt="img"
                                  className="img-fluid ml-2x"
                                />
                              </span>
                            </h6>
                          </div>
                          <div className="right">
                            <Link to={"./farm"}>
                              <button className="btn-transparent">Claim</button>
                            </Link>
                          </div>
                        </div>
                        <p className="lowerparadollar">
                          ~$ {(pendingRew2 && dollarVal?.hygt)
                            ? parseFloat(parseFloat(pendingRew2) * parseFloat(dollarVal?.hygt)).toFixed(6)
                            : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="second">
            <div className="main-heading">
              <h4 className="ml-15">Staking Overview</h4>
            </div>
            <div className="content-main">
              <div className="row">
                <div className="col-xl-4 col-12 padd-sm">
                  <div className="inner-card heightsetcard">
                    <div className="upper-head">
                      <h6>My Active Staking</h6>
                      <Link to={"./earn"}>
                        <button className="btn-transparent">Stake</button>
                      </Link>
                    </div>
                    <div className="bottom-content">
                      <div className="left">
                        <h6>
                          {amountActive
                            ? parseFloat(amountActive).toFixed(10)
                            : 0}{" "}
                          <span>
                            HYDT{" "}
                            <img
                              src="\assests\buttonsvgs\hydt.svg"
                              alt="img"
                              className="img-fluid ml-2x"
                            />
                          </span>
                        </h6>
                      </div>
                    </div>
                    <p className="lowerparadollar">
                      ~$ {(amountActive && dollarVal?.hydt)
                        ? parseFloat(parseFloat(amountActive) * parseFloat(dollarVal?.hydt)).toFixed(10)
                        : 0}{" "}
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-12 padd-sm">
                  <div className="inner-card heightsetcard">
                    <div className="upper-head">
                      <h6>Daily Rewards</h6>
                      {/* <button className="btn-transparent">Claim</button> */}
                    </div>
                    <div className="bottom-content">
                      <div className="left">

                        <h6>
                          {depositRew1
                            ? parseFloat(depositRew1).toFixed(10)
                            : 0}{" "}
                          <span>
                            HYDT{" "}
                            <img
                              src="\assests\buttonsvgs\hydt.svg"
                              alt="img"
                              className="img-fluid ml-2x"
                            />
                          </span>
                          <p className="lowerparadollar">
                            ~$ {(depositRew1 && dollarVal?.hydt)
                              ? parseFloat(parseFloat(depositRew1) * parseFloat(dollarVal?.hydt)).toFixed(10)
                              : 0}{" "}
                          </p>
                        </h6>
                        <h6 className="mt-2">
                          {totHygtReward
                            ? parseFloat(totHygtReward).toFixed(10)
                            : 0}{" "}
                          <span>
                            HYGT{" "}
                            <img
                              src="\assests\buttonsvgs\hygt.svg"
                              alt="img"
                              className="img-fluid ml-2x"
                            />
                          </span>
                          <p className="lowerparadollar">
                            ~${(totHygtReward && dollarVal?.hygt)
                              ? parseFloat(parseFloat(totHygtReward) * parseFloat(dollarVal?.hygt)).toFixed(10)
                              : 0}{" "}
                          </p>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-12 padd-sm">
                  <div className="inner-card heightsetcard">
                    <div className="upper-head">
                      <h6>HYDT/USD Rate</h6>
                      {/* <button className="btn-transparent">Claim</button> */}
                    </div>
                    <div className="bottom-content">
                      <div className="left">
                        <h6>
                          {depositRew2 ? parseFloat(depositRew2).toFixed(7) : 0}{" "}
                          <span>HYDT/USD</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <Modal
          className="request-withdraw-modal welcome-modal-parent"
          show={show1}
          onHide={handleClose1}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Welcome to HYDT Protocol Affiliate Program
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="welcome-modal">
              <h6>
                At vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium voluptatum deleniti atque corrupti quos
                dolores et quas molestias excepturi sint occaecati cupiditate
                non provident, similique sunt in culpa qui officia deserunt
                mollitia animi, id est laborum et dolorum fuga. Et harum quidem
                rerum facilis est et expedita distinctio.
                <br /> <br />
                Nam libero tempore, cum soluta nobis est eligendi optio cumque
                nihil impedit quo minus id quod maxime placeat facere possimus,
                omnis voluptas assumenda est, omnis dolor repellendus.
                Temporibus autem quibusdam et aut officiis debitis aut rerum
                necessitatibus saepe eveniet ut et voluptates repudiandae sint
                et molestiae non recusandae.
              </h6>
            </div>
            <div className="bottom-btns">
              <button
                onClick={handleClose1}
                className="btn-withdraw w-100"
                style={{ flex: "1" }}
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal> */}
      </div>
    </>
  );
}
export default Dashboard;
