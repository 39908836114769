import React, { useEffect, useState } from "react";
import axios from "axios";
import Envirnoment from "../../utils/environment";
import { useWeb3React } from "@web3-react/core";
import "./affliate.scss";
import useWeb3 from "hooks/useWeb3";
import { toast } from "react-toastify";
const Search = () => {
  const [userName, setUserName] = useState("Select");
  let filByTypeArray = ["userName", "walletAddress"];
  const { account } = useWeb3React();
  const accessToken = localStorage.getItem("accessToken");
  const [searchData, setSearchData] = useState();
  const [offset, setOffset] = useState(1);
  const [walletAddress, setWalletAddress] = useState();
  const web3 = useWeb3();
  function getSearchFunc() {
    if ([userName] == "walletAddress") {
      let res = web3.utils.isAddress(walletAddress);
      if (!res) {
        toast.error("Invalid wallet address");
        return;
      }
    }
    const params = {
      [userName]: walletAddress,
    };
    axios
      .get(Envirnoment.apiUrl + "users/get-user", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: params,
      })
      .then((response) => {
        // You can perform additional actions based on the API response
        setSearchData(response?.data?.data?.user);
      })
      .catch((error) => {
        // Handle API errors here
        setSearchData();
        toast.error(error.response?.data?.message);
      })
      .finally(() => {
        // setIsConfirmLoading(false);
      });
  }
  return (
    <>
      <section className="search-affliate">
        <div className="parent">
          <div className="option-field">
            <img
              src="\assests\search-normal.svg"
              alt="img"
              className="img-fluid search-normal"
            />
            <input
              onChange={(e) => setWalletAddress(e.target.value)}
              type="search"
              placeholder="Search..."
            />
            <div class="dropdown">
              <button
                class={
                  userName === "Filter By Type"
                    ? "dropdown-toggle"
                    : "dropdown-toggle text-light"
                }
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                {userName.charAt(0).toUpperCase() + userName.slice(1)}{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M8.99965 12.6C8.47465 12.6 7.94965 12.3975 7.55215 12L2.66215 7.11C2.44465 6.8925 2.44465 6.5325 2.66215 6.315C2.87965 6.0975 3.23965 6.0975 3.45715 6.315L8.34715 11.205C8.70715 11.565 9.29215 11.565 9.65215 11.205L14.5421 6.315C14.7596 6.0975 15.1196 6.0975 15.3371 6.315C15.5546 6.5325 15.5546 6.8925 15.3371 7.11L10.4471 12C10.0496 12.3975 9.52465 12.6 8.99965 12.6Z"
                    fill="white"
                  />
                </svg>
              </button>
              <ul class="dropdown-menu">
                {filByTypeArray?.map((item, id) => {
                  return (
                    <li id={id}>
                      <a
                        onClick={() => setUserName(item)}
                        class={"dropdown-item"}
                        href="#"
                      >
                        {item.charAt(0).toUpperCase() + item.slice(1)}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <button
            onClick={getSearchFunc}
            disabled={!walletAddress && userName === "Select"}
            className={
              walletAddress && userName !== "Select"
                ? "btn-search "
                : "btn-search disable"
            }
          >
            Search
          </button>
        </div>
        {searchData?.length > 0
          ? searchData?.map((item) => {
              return (
                <>
                  <div className="bottom-fields">
                    {(item?.refferee?.userName ||
                      item?.refferee?.walletAddress) && (
                      <div className="upper-field">
                        <h6>
                          {item?.refferee?.userName ||
                            item?.refferee?.walletAddress}{" "}
                          is your 1st Level Referral
                        </h6>
                      </div>
                    )}
                    <div className="bottom-text">
                      <div className="text">
                        <h6>Username</h6>
                        <p>{item?.userName || "N/A"}</p>
                      </div>
                      <div className="text">
                        <h6>Wallet Address</h6>
                        <p>
                          {item?.walletAddress?.slice(0, 6)}...
                          {item?.walletAddress?.slice(-4)}
                        </p>
                      </div>
                      <div className="text">
                        <h6>Registration Date</h6>
                        <p>{item?.createdAt?.slice(0, 10)}</p>
                      </div>
                      <div className="text">
                        <h6>Total HYDT Staked</h6>
                        <p>
                          {item?.totalStakedAmount || 0} <span>HYDT</span>{" "}
                          <img
                            src="\assests\hydt-new-icon.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </p>
                      </div>
                      <div className="text">
                        <h6>Commission Earned</h6>
                        <p>
                          {item?.reward?.totalReward || 0} <span>HYDT</span>{" "}
                          <img
                            src="\assests\hydt-new-icon.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </p>
                      </div>
                      <div className="text">
                        <h6>Level 1 Referral</h6>
                        <p>{item?.directRefferals || 0}</p>
                      </div>
                      <div className="text">
                        <h6>Indirect Referral</h6>
                        <p>{item?.indirectRefferals || 0}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          : null}
        {/* {searchData && (
          <div className="bottom-fields">
            {(searchData?.refferee?.userName ||
              searchData?.refferee?.walletAddress) && (
              <div className="upper-field">
                <h6>
                  {searchData?.refferee?.userName ||
                    searchData?.refferee?.walletAddress}{" "}
                  is your 1st Level Referral
                </h6>
              </div>
            )}
            <div className="bottom-text">
              <div className="text">
                <h6>Username</h6>
                <p>{searchData?.userName || "N/A"}</p>
              </div>
              <div className="text">
                <h6>Wallet Address</h6>
                <p>
                  {searchData?.walletAddress?.slice(0, 6)}...
                  {searchData?.walletAddress?.slice(-4)}
                </p>
              </div>
              <div className="text">
                <h6>Registration Date</h6>
                <p>{searchData?.createdAt?.slice(0, 10)}</p>
              </div>
              <div className="text">
                <h6>Total HYDT Staked</h6>
                <p>
                  {searchData?.totalStakedAmount || 0} <span>HYDT</span>{" "}
                  <img
                    src="\assests\hydt-new-icon.svg"
                    alt="img"
                    className="img-fluid"
                  />
                </p>
              </div>
              <div className="text">
                <h6>Commission Earned</h6>
                <p>
                  {searchData?.reward?.totalReward || 0} <span>HYDT</span>{" "}
                  <img
                    src="\assests\hydt-new-icon.svg"
                    alt="img"
                    className="img-fluid"
                  />
                </p>
              </div>
              <div className="text">
                <h6>Level 1 Referral</h6>
                <p>{searchData?.directRefferals || 0}</p>
              </div>
              <div className="text">
                <h6>Indirect Referral</h6>
                <p>{searchData?.indirectRefferals || 0}</p>
              </div>
            </div>
          </div>
        )} */}
      </section>
    </>
  );
};

export default Search;
