import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3"
import { getPancakeHygtHydtContract } from "../../utils/contractHelpers";
import Environment from "../../utils/environment";

export const PancakeHydtHygt = () => {
    const { account } = useWeb3React();
    const web3 = useWeb3();
    const contractAddress = Environment.pancakeHygtHydt;
    const contract = getPancakeHygtHydtContract(contractAddress, web3);
    const PancakeHydtHygtHook = useCallback(
        async () => {
            // let val = web3.utils.toWei(spendBnb.toString(), "ether");
            try {
                const balance = await contract.methods.getReserves().call()

                return balance
            } catch (error) {
                console.log('9999999', error)
                throw error;
            }
        }, [contract]
    );
    return { PancakeHydtHygtHook: PancakeHydtHygtHook };
}
export default PancakeHydtHygt;

