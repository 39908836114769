// // Set of helper functions to facilitate wallet setup
/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network.
 * If BSC is not available in the wallet, it will be added.
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async () => {
  const provider = window.ethereum;

  if (provider) {
    const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10); // Ensure this is set to BSC's chain ID in your .env file

    try {
      // Try switching to the BSC network
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      });
      return true;
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask
      if (switchError.code === 4902) {
        try {
          // Add the BSC network to MetaMask
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${chainId.toString(16)}`,
                chainName: 'Binance Smart Chain',
                nativeCurrency: {
                  name: 'Binance Coin',
                  symbol: 'BNB',
                  decimals: 18,
                },
                rpcUrls: ['https://bsc-dataseed.binance.org/'],
                blockExplorerUrls: ['https://bscscan.com'],
              },
            ],
          });
          // After adding, switch to the BSC network
          await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${chainId.toString(16)}` }],
          });
          return true;
        } catch (addError) {
          console.error('Failed to add the BSC network:', addError);
          return false;
        }
      } else {
        console.error('Failed to switch to the BSC network:', switchError);
        return false;
      }
    }
  } else {
    console.error("Can't setup the BSC network on MetaMask because window.ethereum is undefined");
    return false;
  }
};

// /**
//  * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
//  * @returns {boolean} true if the setup succeeded, false otherwise
//  */
// export const setupNetwork = async () => {
//   // const provider = ((window.ethereum != null) ? new ethers.providers.Web3Provider(window.ethereum) : ethers.providers.getDefaultProvider());
//   const provider = (window).ethereum
//   if (provider) {
//     const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)
//     // const chainId = 4
//     try {
//       await provider.request({
//         method: 'wallet_switchEthereumChain',
//         params: [
//           {
//             chainId: `0x${chainId.toString(16)}`,
//             // chainName: 'Rinkeby Smart Chain TestNet',
//             // nativeCurrency: {
//             //   name: 'ETH',
//             //   symbol: 'ETH',
//             //   decimals: 18,
//             // },
//             // rpcUrls: [nodes],
//             // blockExplorerUrls: ['https://rinkeby.etherscan.com/'],
//           },
//         ],
//       })
//       return true
//     } catch (error) {
//       console.error(error)
//       return false
//     }
//   } else {
//     console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
//     return false
//   }
// }
// /**
//  * Prompt the user to add a custom token to metamask
//  * @param tokenAddress
//  * @param tokenSymbol
//  * @param tokenDecimals
//  * @param tokenImage
//  * @returns {boolean} true if the token has been added, false otherwise
//  */
// export const registerToken = async (
//   tokenAddress,
//   tokenSymbol,
//   tokenDecimals,
//   tokenImage,
// ) => {
//   const tokenAdded = await (window).ethereum.request({
//     method: 'wallet_watchAsset',
//     params: {
//       type: 'ERC20',
//       options: {
//         address: tokenAddress,
//         symbol: tokenSymbol,
//         decimals: tokenDecimals,
//         image: tokenImage,
//       },
//     },
//   })

//   return tokenAdded
// }
