import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3";
import { initialMintV2Contract } from "../../utils/contractHelpers";
import Environment from "../../utils/environment";
export const ApproveCon = () => {
    const { account } = useWeb3React();
    const web3 = useWeb3();
    const contractAddress = Environment.intialMintV2;
    const contract = initialMintV2Contract(contractAddress, web3);
    const buyHydtCon = useCallback(
        async (spendBnb) => {
            console.log("aaaaaaaaaa", spendBnb);
            let val = web3.utils.toWei(spendBnb.toString(), "ether");
            console.log("11111111", val);
            let gasFunPrice = await web3.eth.getGasPrice().then((result) => {
                var result2 = parseInt(result) + 1000000000
                return result2.toString()
            })
            try {
                const balance = await contract.methods.initialMint().send({ from: account, value: val, gasPrice: gasFunPrice })
                console.log('00000000000', balance);
                return balance
            } catch (error) {
                console.log('9999999', error)
                throw error;
            }
        }, [contract]
    );
    return { buyHydtCon: buyHydtCon };
}
export default ApproveCon;