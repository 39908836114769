import React, { useEffect, useState } from "react";
import axios from "axios";
import Envirnoment from "../../../utils/environment";
import { useWeb3React } from "@web3-react/core";
export default function YourRef({
  sethydtDowntreeStaked,
  sethydtStaked,
  startDate,
  endDate,
}) {
  const { account } = useWeb3React();
  const accessToken = localStorage.getItem("accessToken");
  const [myEarning, setEarning] = useState();
  function getRefFunc() {
    const apiUrl = `${Envirnoment.apiUrl}staking/my-earnings`;

    var config = {
      method: "get",
      url: apiUrl,
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    };

    let dynamicQuery = config.url;

    if (startDate && startDate != null) {
      dynamicQuery += `?startDate=${startDate}&endDate=${endDate}`;
    }
    config.url = dynamicQuery;
    axios(config)
      .then((response) => {
        sethydtStaked(response?.data?.data?.totalStakedAmount);
        sethydtDowntreeStaked(response?.data?.data?.downTreeStakedAmount);

        // You can perform additional actions based on the API response
        setEarning(response?.data?.data);
      })
      .catch((error) => {
        // Handle API errors here
        // toast.error(error.request?.statusText)
      })
      .finally(() => {
        // setIsConfirmLoading(false);
      });
  }
  useEffect(() => {
    if (account) {
      getRefFunc();
    }
  }, [account, startDate, endDate]);
  return (
    <div className="single-card">
      <div className="top-heading">
        <h6>Your Earnings</h6>
      </div>
      <div className="bottom-text">
        <div className="text-top">
          <h2>1st Level</h2>
          <p>{parseFloat(myEarning?.levelOneEarning || 0)?.toFixed(4)} HYDT</p>
        </div>
        <div className="text">
          <h6>2nd Level</h6>
          <p>{myEarning?.levelTwoEarning || 0} HYDT</p>
        </div>
        <div className="text">
          <h6>3rd Level</h6>
          <p>{myEarning?.levelThreeEarning || 0} HYDT</p>
        </div>
        <div className="text">
          <h6>4th Level</h6>
          <p>{myEarning?.levelFourEarning || 0} HYDT</p>
        </div>
        <div className="text">
          <h6>5th Level</h6>
          <p>{myEarning?.levelFiveEarning || 0} HYDT</p>
        </div>
      </div>
    </div>
  );
}
