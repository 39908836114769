import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3";
import { getClaimContract } from "../../utils/contractHelpers";
import Environment from "../../utils/environment";

export const ClaimRefRewards = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contractAddress = Environment.claim;
  const contract = getClaimContract(contractAddress, web3);
  const refRewards = useCallback(
    async (hydt, hygt, vesting, time, v, r, s, bool, _id) => {
      let vest;
      if (vesting === "no vesting") {
        vest = 0;
      } else if (vesting === "three months") {
        vest = 3;
      } else if (vesting === "twelve months") {
        vest = 12;
      }
      let hydtAmount = web3.utils.toWei(
        parseFloat(hydt)?.toFixed(4)?.toString(),
        "ether"
      );
      let hgdtAmount = web3.utils.toWei(
        parseFloat(hygt)?.toFixed(4)?.toString(),
        "ether"
      );
      let chainId = process.env.REACT_APP_CHAIN_ID
      console.log(chainId,'chainid');
      // let hydtAmount = web3.utils.toWei(hydt?.toString(), 'ether')
      // let hgdtAmount = web3.utils.toWei(hygt?.toString(), 'ether')

      try {
        let gasFunPrice = await web3.eth.getGasPrice().then((result) => {
          var result2 = parseInt(result) + 1000000000;
          return result2.toString();
        });
        if (bool) {
          const gas = await contract.methods
            .withdraw(_id)
            .estimateGas({ from: account });

          const balance = await contract.methods
            .withdraw(_id)
            .send({ from: account, gas, gasPrice: gasFunPrice });

          return balance;
        } else {
          const gas = await contract.methods
            .claimWithdraw(hydtAmount, hgdtAmount, vest, time, chainId, v, r, s)
            .estimateGas({ from: account });
          const balance = await contract.methods
            .claimWithdraw(hydtAmount, hgdtAmount, vest, time, chainId, v, r, s)
            .send({ from: account,gas, gasPrice: gasFunPrice });

          return balance;
        }
      } catch (error) {
        console.log("9999999", error);
        throw error;
      }
    },
    [contract]
  );
  return { refRewards: refRewards };
};
export default ClaimRefRewards;
