import React, { useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import { Dropdown } from "react-bootstrap";

export default function CopyReferral({ refLink }) {
  
  
  const [copied, setCopied] = useState(false);
  const [refLinkSt, setRefLinkSt] = useState(null)
  const [showDropdown, setShowDropdown] = useState(false); // Track dropdown state

  function copyFunc(params) {
    copy(params);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  }
  useEffect(() => {
    if (refLink) {
      setRefLinkSt({ name: 'Home', link: `https://hydtlanding.netlify.app/?ref=${refLink}` })
    }
  }, [refLink])
  return (
    <div className="inner-field" style={{padding: '0', paddingRight: '8px'}}>
      <Dropdown onToggle={() => setShowDropdown(!showDropdown)} show={showDropdown} className="appdropdown">
        <Dropdown.Toggle id="dropdown-basic">
          {refLinkSt?.name}
          <img src="/assests/Vector.svg" alt="img" className="rotate" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <a href="#" onClick={() => { setShowDropdown(false); setRefLinkSt({ name: 'Home', link: `https://hydtlanding.netlify.app/?ref=${refLink}` })}} className="dropitem">
            Home
          </a>
          <a href="#" onClick={() => { setShowDropdown(false); setRefLinkSt({ name: 'App', link: `https://hydtaudited.quecko.com/HYDT/earn?ref=${refLink}` })}} className="dropitem">
            App
          </a>
        </Dropdown.Menu>
      </Dropdown>
      
    
      <h6 className="textLowerCase text-truncate">{refLinkSt?.link}</h6>
      {copied ? (
        <a className="btn-copy disable">Copied</a>
      ) : (
        <a onClick={() => copyFunc(refLinkSt?.link)} className="btn-copy">
          Copy
        </a>
      )}
    </div>
  );
}
