import "./affliate.scss";

import YourRef from "./AffiliateSubComp/YourRef";
import YourEarning from "./AffiliateSubComp/YourEarning";
import AffiliateBalance from "./AffiliateSubComp/AffiliateBalance";
import ClaimWithdrawal from "./AffiliateSubComp/ClaimWithdrawal";

import React, { useEffect, useState } from "react";
import axios from "axios";
import Envirnoment from "../../utils/environment";
import { useWeb3React } from "@web3-react/core";
import TopRefByStake from "./AffiliateSubComp/TopRefByStake";
import TopRefByEarnCom from "./AffiliateSubComp/TopRefByEarnCom";
import OneTimeCommRate from "./AffiliateSubComp/OneTimeCommRate";
import AwaitingApproval from "./AffiliateSubComp/AwaitingApproval";
import Loader from "hooks/loader";
import { Modal } from "react-bootstrap";
const AffliateDashboard = ({ startDate, endDate }) => {
  const [hydtStaked, sethydtStaked] = useState(0);
  const [hydtDowntreeStaked, sethydtDowntreeStaked] = useState(0);

  const [loader, setLoader] = useState(false);
  const [afBool, setAfBool] = useState(false);
  const { account } = useWeb3React();
  const accessToken = localStorage.getItem("accessToken");
  const [affiliateWithdrawBalance, setAffiliateWithdrawBalance] = useState();
  let hygtCommission = localStorage.getItem("hygtCommission");

  const [data, setData] = useState([]);
  function getOnetimeCommFunc() {
    axios
      .get(Envirnoment.apiUrl + "commission-rates", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        // You can perform additional actions based on the API response
        setData(response?.data?.data);
      })
      .catch((error) => {
        // Handle API errors here
        // toast.error(error.request?.statusText)
      })
      .finally(() => {
        // setIsConfirmLoading(false);
      });
  }
  useEffect(() => {
    if (account) {
      getOnetimeCommFunc();
    }
  }, [account]);

  function affiliate_Withdraw_Balance_Func() {
    axios
      .get(Envirnoment.apiUrl + "rewards/my-rewards", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log(response?.data?.data);
        // You can perform additional actions based on the API response
        setAffiliateWithdrawBalance(response?.data?.data);
      })
      .catch((error) => {
        // Handle API errors here
        // toast.error(error.request?.statusText)
      })
      .finally(() => {
        // setIsConfirmLoading(false);
      });
  }
  useEffect(() => {
    if (account) {
      affiliate_Withdraw_Balance_Func();
    }
  }, [account, afBool]);


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {loader && <Loader />}
      <section className="affliate-dashboard">
        <div className="upper-cards">
          <YourRef startDate={startDate} endDate={endDate} />
          <YourEarning
            sethydtStaked={sethydtStaked}
            sethydtDowntreeStaked={sethydtDowntreeStaked}
            startDate={startDate}
            endDate={endDate}
          />
          <div className="single-card">
            <div className="top-heading">
              <h6>HYDT Staked</h6>
            </div>
            <div className="bottom-text">
              <div className="third-text">
                <div className="upper-t">
                  <h6>{hydtDowntreeStaked || 0}</h6>
                  <p>HYDT</p>
                  <img
                    src="\assests\hydt-new-icon.svg"
                    alt="img"
                    className="img-fluid"
                  />
                </div>
                <p className="para">Staked under your downline</p>
              </div>
            </div>
          </div>
          <AffiliateBalance
            affiliateWithdrawBalance={affiliateWithdrawBalance}
            account={account}
            setAfBool={setAfBool}
            afBool={afBool}
            hygtCommission={hygtCommission}
          />
          <AwaitingApproval
            affiliateWithdrawBalance={affiliateWithdrawBalance}
            setAfBool={setAfBool}
            afBool={afBool}
            hygtCommission={hygtCommission}
          />
          <ClaimWithdrawal
            affiliateWithdrawBalance={affiliateWithdrawBalance}
            hygtCommission={hygtCommission}
            setLoader={setLoader}
            loader={loader}
          />
        </div>
        {/* for desktop and mobile */}
        <OneTimeCommRate data={data} />

        <div className="bottom-tables displaynoneformobile">
          <div className="top-heading">
            <h6 style={{ textAlign: "start", display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'flex-start' }}>
              Expected annual commission rate <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ cursor: 'pointer' }} onClick={handleShow}>
            <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#555555" />
            <path d="M11.48 14.448C11.1663 14.448 10.912 14.1937 10.912 13.88V13.472C10.912 13.0773 10.976 12.7467 11.104 12.48C11.2427 12.2133 11.4133 11.984 11.616 11.792C11.8293 11.5893 12.0533 11.4027 12.288 11.232C12.5227 11.0507 12.7413 10.8587 12.944 10.656C13.1573 10.4533 13.328 10.2187 13.456 9.952C13.5947 9.67467 13.664 9.344 13.664 8.96C13.664 8.81067 13.6373 8.62933 13.584 8.416C13.5413 8.20267 13.4507 7.99467 13.312 7.792C13.1733 7.57867 12.96 7.40267 12.672 7.264C12.384 7.11467 12.0053 7.04 11.536 7.04C11.0133 7.04 10.592 7.13067 10.272 7.312C9.96267 7.48267 9.728 7.696 9.568 7.952C9.41867 8.208 9.31733 8.45867 9.264 8.704C9.22133 8.94933 9.2 9.14667 9.2 9.296C9.2 9.37067 9.2 9.44533 9.2 9.52V9.52C9.2 9.64371 9.09971 9.744 8.976 9.744H8.2271C8.12014 9.744 8.02927 9.67413 8.016 9.568V9.568C8.00533 9.472 8 9.35467 8 9.216C8 9.03467 8.02667 8.81067 8.08 8.544C8.144 8.26667 8.25067 7.984 8.4 7.696C8.56 7.39733 8.77867 7.12 9.056 6.864C9.33333 6.608 9.68 6.4 10.096 6.24C10.5227 6.08 11.0347 6 11.632 6C12.1867 6 12.656 6.07467 13.04 6.224C13.424 6.36267 13.7387 6.54933 13.984 6.784C14.2293 7.008 14.416 7.25333 14.544 7.52C14.6827 7.776 14.7733 8.02133 14.816 8.256C14.8693 8.49067 14.896 8.688 14.896 8.848C14.896 9.37067 14.8267 9.808 14.688 10.16C14.5493 10.512 14.3733 10.8107 14.16 11.056C13.9467 11.3013 13.7173 11.5147 13.472 11.696C13.2267 11.8773 12.9973 12.0533 12.784 12.224C12.5707 12.3947 12.3947 12.5867 12.256 12.8C12.1173 13.0133 12.048 13.28 12.048 13.6V13.88C12.048 14.1937 11.7937 14.448 11.48 14.448V14.448ZM11.472 17.168C11.1009 17.168 10.8 16.8671 10.8 16.496V16.336C10.8 15.9649 11.1009 15.664 11.472 15.664V15.664C11.8431 15.664 12.144 15.9649 12.144 16.336V16.496C12.144 16.8671 11.8431 17.168 11.472 17.168V17.168Z" fill="#555555" />
          </svg>
            </h6>
          </div>
          <div className="custom-table">
            <div className="upper-head">
              <h6>Monthly Restaking</h6>
              <h6>Level 1 (Direct)</h6>
              <h6>Level 2</h6>
              <h6>Level 3</h6>
              <h6>Level 4</h6>
              <h6>Level 5</h6>
            </div>

            <div className="bottom-body">
              <div className="trow">
                <p>12 Month Staking</p>
                <p>12.2%</p>
                <p>3.05%</p>
                <p>3.05%</p>
                <p>1.53%</p>
                <p>1.53%</p>
              </div>
            </div>
            <div className="bottom-body">
              <div className="trow">
                <p>6 Month Staking</p>
                <p>13.4%</p>
                <p>3.35%</p>
                <p>3.35%</p>
                <p>1.68%</p>
                <p>1.68%</p>
              </div>
            </div>
            <div className="bottom-body">
              <div className="trow">
                <p>3 Month Staking</p>
                <p>13.6%</p>
                <p>3.44%</p>
                <p>3.4%</p>
                <p>1.7%</p>
                <p>1.7%</p>
              </div>
            </div>
          </div>
        </div>
        <div className="displayblockformobile d-none">
          <div className="top-heading">
            <h6 style={{ textAlign: "start", display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'flex-start' }}>
            Expected annual commission rate <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ cursor: 'pointer' }} onClick={handleShow}>
            <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#555555" />
            <path d="M11.48 14.448C11.1663 14.448 10.912 14.1937 10.912 13.88V13.472C10.912 13.0773 10.976 12.7467 11.104 12.48C11.2427 12.2133 11.4133 11.984 11.616 11.792C11.8293 11.5893 12.0533 11.4027 12.288 11.232C12.5227 11.0507 12.7413 10.8587 12.944 10.656C13.1573 10.4533 13.328 10.2187 13.456 9.952C13.5947 9.67467 13.664 9.344 13.664 8.96C13.664 8.81067 13.6373 8.62933 13.584 8.416C13.5413 8.20267 13.4507 7.99467 13.312 7.792C13.1733 7.57867 12.96 7.40267 12.672 7.264C12.384 7.11467 12.0053 7.04 11.536 7.04C11.0133 7.04 10.592 7.13067 10.272 7.312C9.96267 7.48267 9.728 7.696 9.568 7.952C9.41867 8.208 9.31733 8.45867 9.264 8.704C9.22133 8.94933 9.2 9.14667 9.2 9.296C9.2 9.37067 9.2 9.44533 9.2 9.52V9.52C9.2 9.64371 9.09971 9.744 8.976 9.744H8.2271C8.12014 9.744 8.02927 9.67413 8.016 9.568V9.568C8.00533 9.472 8 9.35467 8 9.216C8 9.03467 8.02667 8.81067 8.08 8.544C8.144 8.26667 8.25067 7.984 8.4 7.696C8.56 7.39733 8.77867 7.12 9.056 6.864C9.33333 6.608 9.68 6.4 10.096 6.24C10.5227 6.08 11.0347 6 11.632 6C12.1867 6 12.656 6.07467 13.04 6.224C13.424 6.36267 13.7387 6.54933 13.984 6.784C14.2293 7.008 14.416 7.25333 14.544 7.52C14.6827 7.776 14.7733 8.02133 14.816 8.256C14.8693 8.49067 14.896 8.688 14.896 8.848C14.896 9.37067 14.8267 9.808 14.688 10.16C14.5493 10.512 14.3733 10.8107 14.16 11.056C13.9467 11.3013 13.7173 11.5147 13.472 11.696C13.2267 11.8773 12.9973 12.0533 12.784 12.224C12.5707 12.3947 12.3947 12.5867 12.256 12.8C12.1173 13.0133 12.048 13.28 12.048 13.6V13.88C12.048 14.1937 11.7937 14.448 11.48 14.448V14.448ZM11.472 17.168C11.1009 17.168 10.8 16.8671 10.8 16.496V16.336C10.8 15.9649 11.1009 15.664 11.472 15.664V15.664C11.8431 15.664 12.144 15.9649 12.144 16.336V16.496C12.144 16.8671 11.8431 17.168 11.472 17.168V17.168Z" fill="#555555" />
          </svg>
            </h6>
          </div>
          <div className="bottom-tables-mobile ">
            <div className="inner-detail">
              <div className="text">
                <p>Monthly Restaking</p>
                <h6>12 Month Staking</h6>
              </div>
              <div className="text">
                <p>Level 1 (Direct)</p>
                <h6>12.2%</h6>
              </div>
              <div className="text">
                <p>Level 2</p>
                <h6>3.05%</h6>
              </div>
              <div className="text">
                <p>Level 3</p>
                <h6>3.05%</h6>
              </div>
              <div className="text">
                <p>Level 4</p>
                <h6>1.53%</h6>
              </div>
              <div className="text">
                <p>Level 5</p>
                <h6>1.53%</h6>
              </div>
            </div>
            <div className="inner-detail">
              <div className="text">
                <p>Monthly Restaking</p>
                <h6>6 Month Staking</h6>
              </div>
              <div className="text">
                <p>Level 1 (Direct)</p>
                <h6>13.4%</h6>
              </div>
              <div className="text">
                <p>Level 2</p>
                <h6>3.35%</h6>
              </div>
              <div className="text">
                <p>Level 3</p>
                <h6>3.35%</h6>
              </div>
              <div className="text">
                <p>Level 4</p>
                <h6>1.68%</h6>
              </div>
              <div className="text">
                <p>Level 5</p>
                <h6>1.68%</h6>
              </div>
            </div>
            <div className="inner-detail">
              <div className="text">
                <p>Monthly Restaking</p>
                <h6>12 Month Staking</h6>
              </div>
              <div className="text">
                <p>Level 1 (Direct)</p>
                <h6>13.6%</h6>
              </div>
              <div className="text">
                <p>Level 2</p>
                <h6>3.4%</h6>
              </div>
              <div className="text">
                <p>Level 3</p>
                <h6>3.4%</h6>
              </div>
              <div className="text">
                <p>Level 4</p>
                <h6>1.7%</h6>
              </div>
              <div className="text">
                <p>Level 5</p>
                <h6>1.7%</h6>
              </div>
            </div>

            {/* <div className="inner-detail">
              <div className="text">
                <p>Monthly Staking</p>
                <h6>{data?.stakingType} Month Staking</h6>
              </div>
              <div className="text">
                <p>Level 1 (Direct)</p>
                <h6>{data?.levelOne}%</h6>
              </div>
              <div className="text">
                <p>Level 2</p>
                <h6>{data?.levelTwo}%</h6>
              </div>
              <div className="text">
                <p>Level 3</p>
                <h6>{data?.levelThree}%</h6>
              </div>
              <div className="text">
                <p>Level 4</p>
                <h6>{data?.levelFour}%</h6>
              </div>
              <div className="text">
                <p>Level 5</p>
                <h6>{data?.levelFive}%</h6>
              </div>
            </div> */}
          </div>
        </div>
        {/* 
        <div className="bottom-tables displaynoneformobile">
          <div className="top-heading">
            <h6 style={{ textAlign: "start" }}>
              Restaking yearly commission rate
            </h6>
          </div>
          <div className="custom-table">
            <div className="upper-head">
              <h6>Monthly Restaking</h6>
              <h6>Level 1 (Direct)</h6>
              <h6>Level 2</h6>
              <h6>Level 3</h6>
              <h6>Level 4</h6>
              <h6>Level 5</h6>
            </div>

            {data?.length > 0 ? (
              <div className="bottom-body">
                <div className="trow">
                  <p>{data[2]?.stakingType} Month Staking</p>
                  <p>{data[2]?.levelOne}%</p>
                  <p>{data[2]?.levelTwo}%</p>
                  <p>{data[2]?.levelThree}%</p>
                  <p>{data[2]?.levelFour}%</p>
                  <p>{data[2]?.levelFive}%</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="displayblockformobile d-none">
          <div className="top-heading">
            <h6 style={{ textAlign: "start" }}>
              Restaking yearly commission rate
            </h6>
          </div>
          <div className="bottom-tables-mobile ">
            {data?.length > 0 ? (
              <div className="inner-detail">
                <div className="text">
                  <p>Monthly Restaking</p>
                  <h6>{data[2]?.stakingType} Month Staking</h6>
                </div>
                <div className="text">
                  <p>Level 1 (Direct)</p>
                  <h6>{data[2]?.levelOne}%</h6>
                </div>
                <div className="text">
                  <p>Level 2</p>
                  <h6>{data[2]?.levelTwo}%</h6>
                </div>
                <div className="text">
                  <p>Level 3</p>
                  <h6>{data[2]?.levelThree}%</h6>
                </div>
                <div className="text">
                  <p>Level 4</p>
                  <h6>{data[2]?.levelFour}%</h6>
                </div>
                <div className="text">
                  <p>Level 5</p>
                  <h6>{data[2]?.levelFive}%</h6>
                </div>
              </div>
            ) : null}
            
          </div>
        </div> */}
        <div className="bottom-tables">
          <div className="row">
            <TopRefByStake startDate={startDate} endDate={endDate} />
            <TopRefByEarnCom startDate={startDate} endDate={endDate} />
          </div>
        </div>
      </section>

      <Modal
        className="request-withdraw-modal welcome-modal-parent"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
          One-time commission rate
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="welcome-modal">
            <h6>
              This shows expected annual commission rate from refferal's HYDT Staking amount, assuming your referral compounds earnings by restaking payouts once every month.
            </h6>
          </div>
          <div className="bottom-btns">
            <button
              onClick={handleClose}
              className="btn-withdraw w-100"
              style={{ flex: "1" }}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AffliateDashboard;
