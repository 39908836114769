import React, { useEffect, useState } from "react";
import axios from "axios";
import Envirnoment from "../../../utils/environment";
import { useWeb3React } from "@web3-react/core";
import { Modal } from "react-bootstrap";
import moment from "moment";
export default function AwaitingApproval({
  affiliateWithdrawBalance,
  setAfBool,
  afBool,
  hygtCommission,
}) {
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const { account } = useWeb3React();
  const [awaitApprovalList, setAwaitApprovalList] = useState();
  const accessToken = localStorage.getItem("accessToken");
  const [offset, setOffset] = useState(1);
  const colorArray = {
    pending: "status-orange",
    approved: "status-green",
    claimed: "status-blue",
    rejected: "status-red",
  };
  function getHistoryFunc() {
    const params = {
      offset,
      limit: 10,
    };

    axios
      .get(Envirnoment.apiUrl + "withdraw/get-user-withdraw-list", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: params,
      })
      .then((response) => {
        // You can perform additional actions based on the API response
        setAwaitApprovalList(response?.data?.data?.withdraws);
      })
      .catch((error) => {
        // Handle API errors here
        // toast.error(error.request?.statusText)
      })
      .finally(() => {
        // setIsConfirmLoading(false);
      });
  }

  useEffect(() => {
    if (account) {
      getHistoryFunc();
    }
  }, [account, offset, afBool]);
  console.log(awaitApprovalList);
  return (
    <>
      <div className="single-card">
        <div className="top-heading">
          <h6>Awaiting Approval </h6>
        </div>
        <div className="fourth-set-align">
          <div className="bottom-text">
            <div className="third-text">
              <div className="upper-t">
                <h6>
                  {affiliateWithdrawBalance?.pendingReward < 0.00001 ||
                  affiliateWithdrawBalance?.pendingReward == null ||
                  affiliateWithdrawBalance?.pendingReward === undefined
                    ? 0
                    : parseFloat(
                        affiliateWithdrawBalance?.pendingReward
                      )?.toFixed(4)}
                </h6>
                <p>HYDT</p>
                <img
                  src="\assests\hydt-new-icon.svg"
                  alt="img"
                  className="img-fluid"
                />
              </div>
              <div className="upper-t">
                <h6>
                  {affiliateWithdrawBalance?.pendingHygtReward < 0.00001 ||
                  affiliateWithdrawBalance?.pendingHygtReward == null ||
                  affiliateWithdrawBalance?.pendingHygtReward === undefined
                    ? 0
                    : parseFloat(
                        affiliateWithdrawBalance?.pendingHygtReward || 0
                      )?.toFixed(4)}
                </h6>
                <p>HYGT</p>
                <img
                  src="\assests\hygt-new-icon.svg"
                  alt="img"
                  className="img-fluid"
                />
              </div>
              <div className="upper-t">
                <h6>
                  {affiliateWithdrawBalance?.pendingHygtVestingReward <
                    0.00001 ||
                  affiliateWithdrawBalance?.pendingHygtVestingReward == null ||
                  affiliateWithdrawBalance?.pendingHygtVestingReward ===
                    undefined
                    ? 0
                    : parseFloat(
                        affiliateWithdrawBalance?.pendingHygtVestingReward || 0
                      )?.toFixed(4)}
                </h6>
                <p>HYGT Vesting</p>
                <img
                  src="\assests\hygt-new-icon.svg"
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="bottom-btn">
            <a
              className={
                awaitApprovalList?.length > 0
                  ? "request-btn"
                  : "request-btn disable"
              }
              onClick={() =>
                awaitApprovalList?.length > 0 ? handleShow1() : null
              }
            >
              View Details
            </a>
          </div>
        </div>
      </div>
      <Modal
        className="request-withdraw-modal awaiting-approval-modal"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Awaiting Approval</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="hcvttvdvctvcv">
          <div className="custom-table">
            <div className="upper-head">
              <h6>Date/Time</h6>
              <h6>HYDT Amount</h6>
              <h6>HYGT Amount</h6>
              <h6>HYGT Vesting Periods</h6>
              <h6>Status</h6>
            </div>
            <div className="bottom-body">
              {awaitApprovalList?.map((item, indes) => {
                const dateString = item?.hygtVestingEndTime;
                const date = new Date(dateString);

                // Format the date in the desired format "09/02/2024 3:00 PM"
                const formattedDate = `${(date.getMonth() + 1)
                  .toString()
                  .padStart(2, "0")}/${date
                  .getDate()
                  .toString()
                  .padStart(
                    2,
                    "0"
                  )}/${date.getFullYear()} ${date.toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}`;

                let status = item?.status;
                return (
                  <div className="trow">
                    <p>
                      {moment(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
                    </p>
                    <p>
                      {item?.amount}{" "}
                      <span>
                        HYDT{" "}
                        <img
                          src="\assests\hydt-small-icon.svg"
                          alt="img"
                          className="img-fluid"
                        />{" "}
                      </span>
                    </p>
                    <p>
                      {item?.hygtVestingAmount}{" "}
                      <span>
                        HYGT{" "}
                        <img
                          src="\assests\hygt-small-icon.svg"
                          alt="img"
                          className="img-fluid"
                        />{" "}
                      </span>
                    </p>
                    <p className="text-capitalize">{item?.hygtVestingType}</p>
                    <p className={`${colorArray[status]}  text-capitalize`}>
                      {status}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
