import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3";
import { getClaimContract } from "../../utils/contractHelpers";
import Environment from "../../utils/environment";

export const UserWithdrawals = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contractAddress = Environment.claim;
  const contract = getClaimContract(contractAddress, web3);
  const withdrawals = useCallback(
    async (id) => {
      // let val = web3.utils.toWei(spendBnb.toString(), "ether");
      try {
        const value = await contract.methods
          .userWithdrawals(account, id)
          .call();
        return value;
      } catch (error) {
        console.log("9999999", error);
        throw error;
      }
    },
    [contract]
  );
  return { withdrawals: withdrawals };
};
export default UserWithdrawals;
