import React, { useEffect, useState } from "react";
import axios from "axios";
import Envirnoment from "../../../utils/environment";
import { useWeb3React } from "@web3-react/core";

export default function TopRefByEarnCom({ startDate, endDate }) {
  const { account } = useWeb3React();
  const accessToken = localStorage.getItem("accessToken");
  const [data, setData] = useState();
  const [offset, setOffset] = useState(1);
  function getTopRefByEarnComFunc() {
    const params = {
      offset,
      limit: 5,
    };

    const apiUrl = `${Envirnoment.apiUrl}users/get-top-refferals-by-commission`;

    var config = {
      method: "get",
      url: apiUrl,
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      params: params,
    };

    let dynamicQuery = config.url;

    if (startDate && startDate != null) {
      dynamicQuery += `?startDate=${startDate}&endDate=${endDate}`;
    }
    config.url = dynamicQuery;
    axios(config)
      .then((response) => {
        // You can perform additional actions based on the API response
        setData(response?.data?.data);
      })
      .catch((error) => {
        // Handle API errors here
        // toast.error(error.request?.statusText)
      })
      .finally(() => {
        // setIsConfirmLoading(false);
      });
  }

  useEffect(() => {
    if (account) {
      getTopRefByEarnComFunc();
    }
  }, [account, offset, startDate, endDate]);
  return (
    <div className="col-xl-6 col-12 pl-2 p-0">
      <div className="top-heading">
        <h6>Top 5 Referrals By Earned Commissions</h6>
      </div>
      <div className="custom-table">
        <div className="upper-head">
          <h6>No.</h6>
          <h6>Username</h6>
          <h6>Commissions</h6>
        </div>
        <div className="bottom-body">
          {data?.users?.map((item, id) => {
            return (
              <div className="trow">
                <p>{item?.id}</p>
                <p>{item?.userName || "N/A"}</p>
                <p>{item?.totalRewardAmount || 0}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
