import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Signature from "../../../hooks/userSign";
import { toast } from "react-toastify";
import axios from "axios";
import Envirnoment from "../../../utils/environment";
import ClaimRefRewards from "../../../hooks/dataSenders/claimRefReward";
export default function AffiliateBalance({
  affiliateWithdrawBalance,
  account,
  setAfBool,
  afBool,
  hygtCommission,
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [dollarVal, setDollVal] = useState();
  const [vesting, setvesting] = useState("no vesting");
  const accessToken = localStorage.getItem("accessToken");
  const [amount, setAmount] = useState(0);
  const [loader, setLoader] = useState(false);

  let { refRewards } = ClaimRefRewards();
  const { userSign } = Signature();

  async function requestWithdraw() {
    if (!account) {
      toast.info("Connect Your Wallet");
      return;
    }
    if (!amount) {
      toast.info("No Amount To Claim");
      return;
    }
    // setLoader(true)
    try {
      handleClose();

      //     const sign = await userSign(account?.toString());
      //   let res=await  refRewards(amount, amount, vesting,0,sign)
      let data = {
        // amount,
        vestingPeriod: vesting,
      };
      var config = {
        method: "post",
        url: `${Envirnoment.apiUrl}withdraw/request-withdraw`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      };
      await axios(config)
        .then(function (response) {
          setLoader(false);
          handleShow1();
          toast.success(response?.data?.message);
          setAfBool(!afBool);
        })
        .catch(async function (error) {
          setLoader(false);
          toast.error(error?.response?.data?.message);
        });
    } catch (error) {
      console.log("error", error);
      setLoader(false);

      toast.error("User Denied Sign");
    }
  }
  useEffect(() => {
    let dollVal = localStorage.getItem("TokendollorVal");
    setDollVal(JSON.parse(dollVal));
  }, [account]);
  useEffect(() => {
    setAmount(parseFloat(affiliateWithdrawBalance?.claimableReward));
  }, [affiliateWithdrawBalance]);

  return (
    <>
      <div className="single-card">
        <div className="top-heading">
          <h6>Affiliate Balance</h6>
        </div>
        <div className="fourth-set-align">
          <div className="bottom-text">
            <div className="third-text">
              <div className="upper-t">
                <h6>
                  {affiliateWithdrawBalance?.claimableReward < 0.00001 ||
                  affiliateWithdrawBalance?.claimableReward == null ||
                  affiliateWithdrawBalance?.claimableReward === undefined
                    ? 0
                    : parseFloat(
                        affiliateWithdrawBalance?.claimableReward
                      )?.toFixed(4)}
                </h6>
                <p>HYDT</p>
                <img
                  src="\assests\hydt-new-icon.svg"
                  alt="img"
                  className="img-fluid"
                />
              </div>
              <div className="upper-t">
                <h6>
                  {affiliateWithdrawBalance?.claimableHygtReward < 0.00001 ||
                  affiliateWithdrawBalance?.claimableHygtReward == null ||
                  affiliateWithdrawBalance?.claimableHygtReward === undefined
                    ? 0
                    : parseFloat(
                        affiliateWithdrawBalance?.claimableHygtReward
                      )?.toFixed(4)}

                  {/* {parseFloat(
                    affiliateWithdrawBalance?.claimableReward || 0
                  )?.toFixed(4) * hygtCommission} */}
                </h6>
                <p>HYGT</p>
                <img
                  src="\assests\hygt-new-icon.svg"
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="bottom-btn">
            <a
              data-toggle="tooltip"
              data-placement="top"
              title={
                parseFloat(affiliateWithdrawBalance?.claimableReward) < 10 &&
                "Claim Amount Must Be Greater Then 10"
              }
              className={
                parseFloat(affiliateWithdrawBalance?.claimableReward) > 10
                  ? "request-btn"
                  : "request-btn disable"
              }
              onClick={() =>
                parseFloat(affiliateWithdrawBalance?.claimableReward) > 10
                  ? handleShow()
                  : null
              }
            >
              Request Withdraw
            </a>
          </div>
        </div>
      </div>
      <Modal
        className="request-withdraw-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Request Withdraw</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inner-content">
            <h6>Choose one of HYGT vesting period.</h6>
            <h6>
              HYDT is available for claim upon request approval regardless of
              vesting period choice.{" "}
            </h6>
            <h6>Minimum withdrawal: 10 HYDT</h6>
            <h6>Withdrawal request is usually approved within 7 days.</h6>
          </div>
          <div className="bottom-tabs">
            <Tabs
              defaultActiveKey="novesting"
              id="uncontrolled-tab-example"
              onSelect={(key) => {
                switch (key) {
                  case "novesting":
                    setvesting("no vesting");
                    setAmount(
                      parseFloat(affiliateWithdrawBalance?.claimableReward)
                    );
                    break;
                  case "3month":
                    setvesting("three months");
                    setAmount(
                      parseFloat(affiliateWithdrawBalance?.claimableReward)
                    );
                    break;
                  case "12month":
                    setvesting("twelve months");
                    setAmount(
                      parseFloat(affiliateWithdrawBalance?.claimableReward)
                    );
                    break;
                  default:
                    break;
                }
              }}
            >
              <Tab eventKey="novesting" title="No Vesting">
                <div className="inner-text">
                  <div className="left-text">
                    <h6>
                      {parseFloat(
                        parseFloat(affiliateWithdrawBalance?.claimableReward)
                      )?.toFixed(4)}{" "}
                      <span>HYDT</span>{" "}
                      <img
                        src="\assests\hydt-new-icon.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </h6>
                    <p>
                      ~$
                      {affiliateWithdrawBalance?.claimableReward &&
                      dollarVal?.hydt
                        ? parseFloat(
                            parseFloat(
                              affiliateWithdrawBalance?.claimableReward
                            ) * parseFloat(dollarVal?.hydt)
                          )?.toFixed(4)
                        : " 0"}
                      {/* {parseFloat(
                        parseFloat(affiliateWithdrawBalance?.claimableReward) *
                          parseFloat(dollarVal?.hydt)
                      )?.toFixed(4)} */}
                    </p>
                  </div>
                  <span className="plus-sign">+</span>
                  <div className="right-text">
                    <h6>
                      {parseFloat(
                        parseFloat(affiliateWithdrawBalance?.claimableReward) *
                          hygtCommission
                      )}{" "}
                      <span>HYGT</span>{" "}
                      <img
                        src="\assests\hygt-new-icon.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </h6>
                    <p>
                      ~$
                      {affiliateWithdrawBalance?.claimableReward &&
                      dollarVal?.hygt
                        ? parseFloat(
                            parseFloat(
                              affiliateWithdrawBalance?.claimableReward
                            ) *
                              hygtCommission *
                              parseFloat(dollarVal?.hygt)
                          )?.toFixed(4)
                        : " 0"}
                      {/* {parseFloat(
                        parseFloat(affiliateWithdrawBalance?.claimableReward) *
                          hygtCommission *
                          parseFloat(dollarVal?.hygt)
                      )?.toFixed(4)} */}
                    </p>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="3month" title="3 Months (2.5x)">
                <div className="inner-text">
                  <div className="left-text">
                    <h6>
                      {parseFloat(
                        parseFloat(affiliateWithdrawBalance?.claimableReward)
                      )?.toFixed(4)}{" "}
                      <span>HYDT</span>{" "}
                      <img
                        src="\assests\hydt-new-icon.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </h6>
                    <p>
                      ~$
                      {affiliateWithdrawBalance?.claimableReward &&
                      dollarVal?.hydt
                        ? parseFloat(
                            parseFloat(
                              affiliateWithdrawBalance?.claimableReward
                            ) * parseFloat(dollarVal?.hydt)
                          )?.toFixed(4)
                        : " 0"}
                      {/* {parseFloat(
                        parseFloat(affiliateWithdrawBalance?.claimableReward) *
                          parseFloat(dollarVal?.hydt)
                      )?.toFixed(4)} */}
                    </p>
                  </div>
                  <span className="plus-sign">+</span>
                  <div className="right-text">
                    <h6>
                      {parseFloat(
                        parseFloat(affiliateWithdrawBalance?.claimableReward) *
                          hygtCommission *
                          2.5
                      )?.toFixed(4)}{" "}
                      <span>HYGT</span>{" "}
                      <img
                        src="\assests\hygt-new-icon.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </h6>
                    <p>
                      ~$
                      {affiliateWithdrawBalance?.claimableReward &&
                      dollarVal?.hygt
                        ? parseFloat(
                            parseFloat(
                              affiliateWithdrawBalance?.claimableReward
                            ) *
                              hygtCommission *
                              parseFloat(dollarVal?.hygt) *
                              2.5
                          )?.toFixed(4)
                        : " 0"}
                      {/* {parseFloat(
                        parseFloat(affiliateWithdrawBalance?.claimableReward) *
                          hygtCommission *
                          parseFloat(dollarVal?.hygt) *
                          2.5
                      )?.toFixed(4)} */}
                    </p>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="12month" title="12 Months (10x)">
                <div className="inner-text">
                  <div className="left-text">
                    <h6>
                      {parseFloat(
                        parseFloat(affiliateWithdrawBalance?.claimableReward)
                      )?.toFixed(4)}{" "}
                      <span>HYDT</span>{" "}
                      <img
                        src="\assests\hydt-new-icon.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </h6>
                    <p>
                      ~$
                      {affiliateWithdrawBalance?.claimableReward &&
                      dollarVal?.hydt
                        ? parseFloat(
                            parseFloat(
                              affiliateWithdrawBalance?.claimableReward
                            ) * parseFloat(dollarVal?.hydt)
                          )?.toFixed(4)
                        : " 0"}
                      {/* {parseFloat(
                        parseFloat(affiliateWithdrawBalance?.claimableReward) *
                          parseFloat(dollarVal?.hydt)
                      )?.toFixed(4)} */}
                    </p>
                  </div>
                  <span className="plus-sign">+</span>
                  <div className="right-text">
                    <h6>
                      {parseFloat(
                        parseFloat(affiliateWithdrawBalance?.claimableReward) *
                          hygtCommission *
                          20
                      )?.toFixed(4)}{" "}
                      <span>HYGT</span>{" "}
                      <img
                        src="\assests\hygt-new-icon.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </h6>
                    <p>
                      ~$
                      {affiliateWithdrawBalance?.claimableReward &&
                      dollarVal?.hygt
                        ? parseFloat(
                            parseFloat(
                              affiliateWithdrawBalance?.claimableReward
                            ) *
                              hygtCommission *
                              parseFloat(dollarVal?.hygt) *
                              20
                          )?.toFixed(4)
                        : " 0"}
                      {/* {parseFloat(
                        parseFloat(affiliateWithdrawBalance?.claimableReward) *
                          hygtCommission *
                          parseFloat(dollarVal?.hygt) *
                          25
                      )?.toFixed(4)} */}
                    </p>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
          <div className="bottom-btns">
            <button
              className="btn-cancel"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </button>
            <button
              className="btn-withdraw"
              disabled={loader}
              onClick={() => {
                setLoader(true);
                //   handleClose();
                //   handleShow1();
                requestWithdraw();
              }}
            >
              Request Withdraw
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="request-withdraw-modal"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Request Withdraw</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="request-completed">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="79"
              height="79"
              viewBox="0 0 79 79"
              fill="none"
            >
              <path
                d="M39.4999 6.58337C21.3214 6.58337 6.58325 21.3208 6.58325 39.5C6.58325 57.6793 21.3214 72.4167 39.4999 72.4167C57.6805 72.4167 72.4166 57.6793 72.4166 39.5C72.4166 21.3208 57.6798 6.58337 39.4999 6.58337ZM33.4617 56.4087L18.9678 41.9155L23.7987 37.0846L33.4617 46.747L55.2012 25.0068L60.032 29.8377L33.4617 56.4087Z"
                fill="#50BF29"
              />
            </svg>
            <h6>Withdrawal Request Submitted</h6>
            <button className="btn-okay" onClick={handleClose1}>
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
